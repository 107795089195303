import {
  VideoVerticalOutlineIcon,
  GalleryFavOutlineIcon,
  CalendarOutlineIcon,
} from "@/src/component/icon/SvgIcon";
import Images from "@/src/component/image/Images";
import Meta from "@/src/component/meta/Meta";
import DefaultLayout from "@/src/layouts/default";
import AppPromotionSection from "@/src/layouts/others/AppPromotionSection";
import GradientSection from "@/src/layouts/others/GradientSection";
import HeroSection from "@/src/layouts/others/HeroSection";
import NewHTWMobileSlider from "@/src/layouts/others/NewHTWMobileSlider";
import NewHTWSlider from "@/src/layouts/others/NewHTWSlider";
import TestimoniSection from "@/src/layouts/others/TestimoniSection";
import { client } from "@/src/utils/axiosStrapi";
import { PartnershipProps, ReviewStrapiProps } from "@/src/utils/globalTypes";
import { GetServerSideProps, InferGetServerSidePropsType } from "next";
import Image from "next/image";
import { AnimationOnScroll } from "react-animation-on-scroll";

const HomePage = ({
  detail,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return (
    <>
      <Meta
        title="StorageHunt: Online Self Storage Unit Auctions Marketplace"
        description="StorageHunt is a mobile marketplace for storage unit auctions that features self-storage auctions. Buy local and abandoned storage units online, join live storage auctions, explore storage locker auctions, and bid on storage units from facilities in all 50 states."
      />
      <DefaultLayout>
        <HeroSection />

        <GradientSection>
          <div className="mt-16 flex min-h-[988px] w-full items-center justify-center rounded-[3rem] bg-white px-4 py-16 md:px-8 lg:mt-32 lg:px-0 lg:py-32 ">
            <div className="relative grid w-full max-w-[58rem] gap-28 text-neutral-900 xl:max-w-5xl xl:gap-40 2xl:max-w-6xl">
              {/* ECLIPSE */}
              <div className="absolute inset-x-1/4 inset-y-0 z-0">
                <Images
                  src="/images/ea/new-eclipse.svg"
                  alt="Enhanced Bidding Experience"
                  className="h-full w-full rotate-180"
                />
              </div>
              {/* KONTEN 1 */}
              <div className="z-10 flex flex-col items-center gap-12 lg:flex-row">
                <div className="flex w-full flex-col gap-3 text-center md:gap-4 lg:w-1/2 lg:gap-6 lg:text-left">
                  <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                    <h2 className="font-tilt-warp text-title3 md:text-title1 lg:text-title1/[4.5rem]">
                      Enhanced Bidding Experience
                    </h2>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                    <p className="text-regular-normal/5 md:text-title3/9 text-neutral-500 lg:max-w-lg">
                      StorageHunt equips you with essential details –
                      high-quality photos and videos of each storage unit –
                      aiding your bidding decisions.
                    </p>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    className="flex flex-wrap items-center justify-center gap-2 lg:justify-start lg:gap-3.5"
                    animateIn="animate__fadeIn"
                    animateOnce
                  >
                    <div className="flex w-full max-w-[10.438rem] items-center gap-2 border-r border-neutral-200">
                      <div className="w-fit text-[#292D32]">
                        <VideoVerticalOutlineIcon width="24" height="24" />
                      </div>
                      <div className="text-small-normal text-neutral-600">
                        Play Unit Video
                      </div>
                    </div>
                    <div className="flex w-full max-w-[10.438rem] items-center gap-2 border-r border-neutral-200">
                      <div className="w-fit text-[#292D32]">
                        <GalleryFavOutlineIcon width="24" height="24" />
                      </div>
                      <div className="text-small-normal text-neutral-600">
                        View Unit Images
                      </div>
                    </div>
                    <div className="flex w-full max-w-[10.438rem] items-center gap-2">
                      <div className="w-fit text-[#292D32]">
                        <CalendarOutlineIcon width="24" height="24" />
                      </div>
                      <div className="text-small-normal text-neutral-600">
                        Auction Time
                      </div>
                    </div>
                  </AnimationOnScroll>
                </div>
                <div className="bg-primary-100 relative flex h-full w-full items-center justify-center rounded-3xl lg:w-1/2">
                  <AnimationOnScroll
                    className="flex w-fit justify-center"
                    animateIn="animate__zoomIn"
                    animateOnce
                  >
                    <Image
                      src="/images/ea/image-1.svg"
                      alt="Enhanced Bidding Experience"
                      unoptimized
                      width={0}
                      height={0}
                      loading="eager"
                      className="w-2/3 md:w-4/5 lg:mt-[2.6rem] lg:w-auto xl:mt-0"
                    />
                  </AnimationOnScroll>

                  <AnimationOnScroll
                    className="absolute -bottom-14 -left-8 md:-bottom-16 md:left-0 lg:-bottom-24 lg:-left-16"
                    animateIn="animate__fadeIn"
                    animateOnce
                  >
                    <AnimationOnScroll
                      animateIn="animate__slideInUp"
                      animateOnce
                      delay={0.5}
                    >
                      <Image
                        src="/images/ea/video-1b.svg"
                        alt="Enhanced Bidding Experience"
                        unoptimized
                        width={0}
                        height={0}
                        loading="eager"
                        className="w-2/3 md:w-5/6 lg:w-auto"
                      />
                    </AnimationOnScroll>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    className="absolute -right-16 -top-14 md:right-20 lg:-right-4 lg:-top-16 xl:right-4"
                    animateIn="animate__fadeIn"
                    animateOnce
                  >
                    <AnimationOnScroll
                      animateIn="animate__slideInDown"
                      animateOnce
                      delay={0.5}
                    >
                      <Image
                        src="/images/ea/video-1a.svg"
                        alt="Enhanced Bidding Experience"
                        className="w-2/3 md:w-4/5 lg:w-auto"
                        unoptimized
                        width={0}
                        height={0}
                        loading="eager"
                      />
                    </AnimationOnScroll>
                  </AnimationOnScroll>
                </div>
              </div>

              {/* KONTEN 4 */}
              <div className="z-10 flex flex-col-reverse items-center gap-12 lg:flex-row">
                <div className="bg-primary-50 relative flex h-full w-full items-center justify-center rounded-3xl lg:w-1/2 ">
                  <AnimationOnScroll
                    className="flex w-fit justify-center"
                    animateIn="animate__zoomIn"
                    animateOnce
                  >
                    <Image
                      src="/images/ea/image-4.svg"
                      alt="Discover Auctions On The Go"
                      className="w-2/3 md:w-auto"
                      unoptimized
                      width={0}
                      height={0}
                      loading="eager"
                    />
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    className="absolute -left-4 -top-8 w-auto max-w-[17rem] md:-left-8 md:top-8 md:w-full lg:top-0 2xl:top-8"
                    animateIn="animate__fadeIn"
                    animateOnce
                  >
                    <AnimationOnScroll
                      className="shadow-lv3 flex flex-col gap-1.5 rounded-[0.875rem] bg-white p-3.5"
                      animateIn="animate__slideInDown"
                      animateOnce
                      delay={0.5}
                    >
                      <div className="text-small-none md:text-regular-none font-bold text-neutral-900">
                        🎉 You Won the Auction!
                      </div>
                      <div className="text-xs-normal md:text-small-normal text-neutral-700">
                        #A001 is yours for $120
                      </div>
                    </AnimationOnScroll>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    className="absolute -bottom-4 right-2 w-auto max-w-[17rem] md:bottom-0 md:right-4 md:w-full"
                    animateIn="animate__fadeIn"
                    animateOnce
                  >
                    <AnimationOnScroll
                      className="shadow-lv3 flex flex-col gap-1.5 rounded-[0.875rem] bg-white p-3.5"
                      animateIn="animate__slideInUp"
                      animateOnce
                      delay={0.5}
                    >
                      <div className="text-regular-none font-bold text-neutral-900">
                        🙁 {`You We’re Out-bided`}
                      </div>
                      <div className="text-small-normal text-neutral-700">
                        #A001 is outbidded to $150 by Mixxxx Jxxxx
                      </div>
                    </AnimationOnScroll>
                  </AnimationOnScroll>
                </div>
                <div className="flex w-full flex-col gap-3 text-center md:gap-4 lg:w-1/2 lg:gap-6 lg:text-left">
                  <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                    <h2 className="font-tilt-warp text-title3 md:text-title1 lg:text-title1/[4.5rem]">
                      Discover Auctions
                      <br /> On The Go
                    </h2>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                    <p className="text-regular-normal/5 md:text-title3/9 text-neutral-500 lg:max-w-lg">
                      Access StorageHunt app on your mobile device anywhere,
                      receiving instant push notifications for new auction
                      listings, updates, and more.
                    </p>
                  </AnimationOnScroll>
                </div>
              </div>

              {/* KONTEN 5 */}
              <div className="z-10 flex flex-col items-center gap-12 lg:flex-row">
                <div className="flex w-full flex-col gap-3 text-center md:gap-4 lg:w-1/2 lg:gap-6 lg:text-left">
                  <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                    <h2 className="font-tilt-warp text-title3 md:text-title1 lg:text-title1/[4.5rem]">
                      App-Based Payments
                    </h2>
                  </AnimationOnScroll>
                  <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                    <p className="text-regular-normal/5 md:text-title3/9 text-neutral-500 lg:max-w-lg">
                      Accepted payment methods for deposits and winning bids
                      include Visa, Mastercard, AMEX, Discover, Apple Pay, and
                      Google Pay.
                    </p>
                  </AnimationOnScroll>
                </div>
                <div className="bg-primary-100 relative flex h-full w-full items-center justify-center rounded-3xl py-6 md:py-11 lg:w-1/2">
                  <AnimationOnScroll
                    className="flex h-full w-fit justify-center"
                    animateIn="animate__zoomIn"
                    animateOnce
                  >
                    <Image
                      src="/images/ea/image-5.png"
                      alt="App-Based Payments"
                      className="w-2/3 md:w-auto"
                      unoptimized
                      width={0}
                      height={0}
                      loading="eager"
                    />
                  </AnimationOnScroll>

                  <AnimationOnScroll
                    className="absolute -right-28 -top-8 md:-right-8 md:-top-8 lg:-top-16"
                    animateIn="animate__fadeIn"
                    animateOnce
                  >
                    <AnimationOnScroll
                      animateIn="animate__slideInDown"
                      animateOnce
                      delay={0.5}
                    >
                      <Image
                        src="/images/ea/image-5a.svg"
                        alt="App-Based Payments"
                        className="w-2/3 md:w-auto"
                        unoptimized
                        width={0}
                        height={0}
                        loading="eager"
                      />
                    </AnimationOnScroll>
                  </AnimationOnScroll>
                  <AnimationOnScroll
                    className="absolute -bottom-12 left-0 md:-bottom-20 lg:-bottom-12 xl:-bottom-16 xl:-left-20"
                    animateIn="animate__fadeIn"
                    animateOnce
                  >
                    <AnimationOnScroll
                      animateIn="animate__slideInUp"
                      animateOnce
                      delay={0.5}
                    >
                      <Image
                        src="/images/ea/image-5b.png"
                        alt="App-Based Payments"
                        className="w-full max-w-none xl:w-auto"
                        unoptimized
                        width={0}
                        height={0}
                        loading="eager"
                      />
                    </AnimationOnScroll>
                  </AnimationOnScroll>
                </div>
              </div>
            </div>
          </div>
        </GradientSection>

        {/* BECAME A SELLER */}
        <div className="lg:px-4.5 2xl:px-default bg-primary-500/10 lg:container lg:bg-transparent">
          {/* DESKTOP */}
          <NewHTWSlider />

          <NewHTWMobileSlider />
        </div>

        {/* FIND AUCTIONS */}
        {detail.partnerships.length > 0 && (
          <div className="lg:px-4.5 2xl:px-default mt-16 lg:container lg:mt-32">
            <h2 className="xl:text-title font-tilt-warp px-4.5 flex flex-col items-center justify-center text-[36px]/[36px] text-neutral-900">
              Find Auctions From
            </h2>

            <div className="mt-11.5 sidebar flex flex-row items-center gap-x-10 overflow-x-auto md:gap-x-[3.75rem] 2xl:justify-center">
              {detail.partnerships.map((e, key: number) => (
                <AnimationOnScroll
                  key={key}
                  className="hover-anime group relative min-w-fit grayscale hover:grayscale-0"
                  animateIn="animate__fadeIn"
                  animateOnce
                >
                  <div className="hover-anime absolute left-0 z-0 h-full w-full bg-white/50 group-hover:bg-transparent" />
                  <Image
                    src={e.attributes.image.data.attributes.url}
                    alt={
                      e.attributes.image.data.attributes.alternativeText ||
                      "Find Auctions From"
                    }
                    unoptimized
                    width={0}
                    height={0}
                    loading="eager"
                    className="w-full md:h-auto"
                  />
                </AnimationOnScroll>
              ))}
            </div>
          </div>
        )}

        {detail.reviews.length > 0 && (
          <TestimoniSection
            title="What Storage Hunters Are Saying"
            items={detail.reviews}
            noName
          />
        )}

        <AppPromotionSection
          alt="Ready to Elevate Your Storage Unit Hunting Game?"
          desc={
            <p className="text-regular-normal mt-3.5 text-center font-medium text-neutral-900 lg:max-w-[30rem] lg:text-left">
              Download the StorageHunt App from the App Store or Google Play
              Store to get started.
            </p>
          }
          title={
            <h2>
              Ready to Elevate Your <br />
              Storage Unit Hunting Game?
            </h2>
          }
        />
      </DefaultLayout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps<{
  detail: {
    partnerships: PartnershipProps[];
    reviews: ReviewStrapiProps[];
  };
}> = async (context) => {
  const mode = process.env.MAINTENANCE_ENABLED as string;
  if (JSON.parse(mode) === true) {
    return {
      redirect: {
        destination: `/maintenance`,
        permanent: false,
      },
    };
  }

  let partnerships = [];
  let reviews = [];
  try {
    const {
      data: { data: partners },
    } = await client.get("/partnerships", {
      params: {
        populate: "*",
        "sort[0]": "queue:asc",
        "pagination[page]": 1,
        "pagination[pageSize]": 10,
        "filters[$or][2][keyword][$containsi]": "",
      },
    });
    partnerships = partners;

    const {
      data: { data: revs },
    } = await client.get("/reviews", {
      params: {
        populate: "*",
        "sort[0]": "createdAt:desc",
        "pagination[page]": 1,
        "pagination[pageSize]": 10,
        "filters[$or][2][keyword][$containsi]": "",
      },
    });
    reviews = revs;
  } catch (error) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      detail: {
        partnerships,
        reviews,
      },
    },
  };
};

export default HomePage;
