import Images from "@/src/component/image/Images";
import { loadingGif, mainImage } from "@/src/helpers/helper";
import { new_how_it_work } from "@/src/helpers/list";
import { useEffect, useRef, useState } from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";

type Props = {
  onBack?: () => void;
  trigger?: boolean;
  onChange?: () => void;
};

const NewHTWSlider = ({ onBack, trigger, onChange }: Props) => {
  const [gif, setGif] = useState(mainImage);
  const [selectGif, setSelectGif] = useState("");
  const [selected, setSelected] = useState("");
  const [type, setType] = useState("image");
  const [selectType, setSelectType] = useState("image");
  const wrapperRef = useRef<any>(null);
  const [bas, setBas] = useState(0);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setGif(mainImage);
        setSelectGif("");
        setSelected("");
        setSelectType("image");
        setType("image");
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  return (
    <>
      <div className="relative hidden h-[60.25rem] w-full items-center justify-center rounded-[3rem] bg-neutral-900 lg:flex">
        <div className="grid w-full max-w-4xl grid-cols-2 gap-4 text-white xl:max-w-5xl xl:gap-8 2xl:max-w-6xl 2xl:gap-[4rem]">
          <div className="relative flex flex-col items-center justify-center">
            <div className="absolute inset-x-0 flex flex-col items-center justify-center">
              <Images
                src="/images/Illustration/bg-neutral-800.svg"
                alt="How Does it work?"
                className="w-[30.625rem]"
              />
            </div>
            <div className="z-0 flex items-center justify-center">
              <AnimationOnScroll
                className="flex w-full justify-center"
                animateIn="animate__fadeIn"
                animateOnce
              >
                {type === "video" ? (
                  <video
                    autoPlay
                    loop
                    muted
                    className="shadow-lv3 w-full rounded-3xl border-[10px] border-white"
                    src={gif || selectGif}
                    poster={loadingGif}
                    playsInline
                  ></video>
                ) : (
                  <Images
                    alt={new_how_it_work[bas].title}
                    className="shadow-lv3 w-[82%] rounded-3xl border-[10px] border-white"
                    src={gif || selectGif}
                  />
                )}
              </AnimationOnScroll>
            </div>
          </div>
          <div className="flex flex-col gap-12 lg:w-[34.125rem]">
            <h2 className="text-title1 xl:text-title font-tilt-warp">
              How Does it work?
            </h2>

            <div ref={wrapperRef} className="grid grid-cols-1 gap-7">
              {new_how_it_work.map((e, key) => (
                <AnimationOnScroll
                  key={key}
                  animateIn="animate__zoomIn"
                  animateOnce
                >
                  <div
                    className={`p-4.5 group ${
                      key.toString() === selected
                        ? "bg-neutral-800"
                        : "bg-transparent"
                    } hover-anime flex cursor-pointer gap-6 rounded-[0.875rem] hover:bg-neutral-800`}
                    onMouseEnter={() => {
                      setGif(e.media);
                      setType(e.type);
                    }}
                    onMouseLeave={() => {
                      if (!selectGif) {
                        setGif(mainImage);
                        setType("image");
                      } else {
                        setGif("");
                        setType(selectType);
                      }
                    }}
                    onClick={() => {
                      setSelectGif(e.media);
                      setGif("");
                      setSelected(key.toString());
                      setSelectType(e.type);
                      onChange?.();
                      setBas(key);
                    }}
                  >
                    <div
                      className={`h-fit w-fit rounded-xl p-2.5 ${
                        key.toString() === selected
                          ? "bg-primary-500"
                          : "bg-neutral-800"
                      } group-hover:bg-primary-500 hover-anime`}
                    >
                      <e.Icon width="34px" height="34px" />
                    </div>
                    <div className="flex flex-col space-y-3">
                      <h2 className="text-title3 font-bold">{e.title}</h2>
                      <p className="text-large-normal text-neutral-200">
                        {e.subtitle}
                      </p>
                    </div>
                  </div>
                </AnimationOnScroll>
              ))}
            </div>
          </div>
        </div>
        <AnimationOnScroll
          className="absolute -left-24 bottom-[-8%] xl:left-4"
          animateIn="animate__zoomIn"
          animateOnce
        >
          <Images
            src="/images/Illustration/person.svg"
            alt="How Does it work?"
            className="w-2/3 -scale-x-[1] transform xl:w-auto"
          />
        </AnimationOnScroll>
      </div>
    </>
  );
};

export default NewHTWSlider;
