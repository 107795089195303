import {
  ArrowLeftOutlineIcon,
  ArrowRightOutlineIcon,
} from "@/src/component/icon/SvgIcon";
import Images from "@/src/component/image/Images";
import Slider from "@/src/component/slider/Slider";
import { loadingGif } from "@/src/helpers/helper";
import { new_how_it_work } from "@/src/helpers/list";
import { useState } from "react";

const NewHTWMobileSlider = () => {
  const [bas, setBas] = useState(0);

  return (
    <>
      {/* MOBILE */}
      <div className="w-full bg-neutral-900 min-h-[692px] rounded-[3rem] px-4.5 py-12 flex flex-col justify-between items-center text-white gap-12 lg:hidden">
        <div className="text-[2.25rem]/[36px] font-tilt-warp text-center w-full">
          How Does it work?
        </div>
        <div className="flex flex-col items-center justify-center relative">
          <div className="absolute inset-x-0 flex flex-col items-center justify-center">
            <Images
              src="/images/Illustration/bg-neutral-800.svg"
              alt="How Does it work?"
              className="w-[490px]"
            />
          </div>

          <div className="z-0 w-full flex justify-center">
            {/* NAVIGATORS */}
            <div className="absolute left-0 inset-y-0 flex flex-col items-center justify-center z-[1]">
              <div
                className="bg-white rounded-full p-2.5 text-neutral-900 shadow-lv3"
                onClick={() => {
                  if (bas === 0) {
                    setBas(new_how_it_work.length - 1);
                  } else {
                    setBas(bas - 1);
                  }
                }}
              >
                <ArrowLeftOutlineIcon width="20px" height="20px" />
              </div>
            </div>
            <div className="absolute right-0 inset-y-0 flex flex-col items-center justify-center z-[1]">
              <div
                className="bg-white rounded-full p-2.5 text-neutral-900 shadow-lv3"
                onClick={() => {
                  if (bas === new_how_it_work.length - 1) {
                    setBas(0);
                  } else {
                    setBas(bas + 1);
                  }
                }}
              >
                <ArrowRightOutlineIcon width="20px" height="20px" />
              </div>
            </div>
            {/* <Slider
              selectedItem={bas}
              onChange={(i) => {
                setBas(i);
              }}
            >
              {new_how_it_work.map((e, key) => (
                <div key={key} className="w-full flex justify-center">
                  {e.type === "video" ? (
                    <video
                      autoPlay
                      loop
                      muted
                      className="w-[60%] rounded-3xl border-[10px] border-white shadow-lv3"
                      src={e.media}
                      poster={loadingGif}
                    ></video>
                  ) : (
                    <Images
                      alt="how-it-work"
                      className="!w-[60%] rounded-3xl border-[10px] border-white shadow-lv3"
                      src={e.media}
                    />
                  )}
                </div>
              ))}
            </Slider> */}
            <div className="w-full flex justify-center">
              {new_how_it_work[bas].type === "video" ? (
                <video
                  autoPlay
                  loop
                  muted
                  className="w-[63%] rounded-3xl border-[10px] border-white shadow-lv3"
                  src={new_how_it_work[bas].media}
                  poster={loadingGif}
                  playsInline
                ></video>
              ) : (
                <Images
                  alt={new_how_it_work[bas].title}
                  className="!w-[60%] rounded-3xl border-[10px] border-white shadow-lv3"
                  src={new_how_it_work[bas].media}
                />
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center text-center">
          {/* INDICATORS */}
          <div className="flex items-center justify-center gap-1.5">
            {new_how_it_work.map((_, key) => (
              <div
                key={key}
                className={`h-2.5 rounded-full hover-anime ${
                  bas === key ? "w-7 bg-primary-500" : "w-2.5 bg-neutral-700"
                } transition-width`}
              ></div>
            ))}
          </div>
          <div className="text-white text-large-none font-bold mt-4.5">
            {new_how_it_work[bas].title}
          </div>
          <div className="text-neutral-300 text-xs-normal mt-3 w-full max-w-sm min-h-[2rem]">
            {new_how_it_work[bas].subtitle}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewHTWMobileSlider;
